<template>
  <div class="parent">
    <div class="top">
      <div class="main">
        <div class="title" @click="toIndex()">
          <p><i class="el-icon-arrow-left"></i> 返回主页</p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-form">
        <div style="">
          <div style="text-align: center;font-size: 40px">注册</div>
          <br>
          <br>

          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item style="width: 400px;height: 70px; margin: 0 auto" prop="name">
              <el-input v-model="form.name" :clearable="true" placeholder="请输入品牌名称"></el-input>
            </el-form-item>
            <el-form-item style="width: 400px;height: 70px; margin: 0 auto" prop="shop">
              <el-input v-model="form.shop" :clearable="true" placeholder="请输入默认店铺名称"></el-input>
            </el-form-item>
            <el-form-item style="width: 400px;height: 70px; margin: 0 auto" prop="empName">
              <el-input v-model="form.empName" :clearable="true" placeholder="请输入主理人名称"></el-input>
            </el-form-item>
            <el-form-item style="width: 400px;height: 70px; margin: 0 auto" prop="city">
              <el-input v-model="form.city" :clearable="true" placeholder="请输入店铺所在城市"></el-input>
            </el-form-item>
            <el-form-item style="width: 400px;height: 70px; margin: 0 auto" prop="phone">
              <el-input v-model="form.phone" :clearable="true" type="number" placeholder="请输入手机号.."></el-input>
            </el-form-item>


            <el-form-item style="width: 400px;height:70px;margin: 0 auto" prop="smsCode">
              <el-input v-model="form.smsCode" type="number" placeholder="请输入短信验证码"
                        style="width: 240px;display: inline-block"></el-input>
              <el-button type="primary" @click="getSms()" :disabled="getSmsState"
                         style="width: 140px;height: 40px;display: inline-block;margin-left: 20px">获取验证码
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="checkRegister()" style="width: 300px;height: 50px">注册</el-button>
            </el-form-item>
            <br>
            <el-form-item>
              <p>已有账号?</p><a @click="toLogin()">立即登陆</a>
            </el-form-item>
          </el-form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "register",
  data() {
    return {
      form: {
        name: "",
        empName: "",
        phone: "",
        shop: "",
        city: "",
        smsCode: "",
      },
      getSmsState: false,
      rules: {
        name: [
          {required: true, message: '请输入公司名称', trigger: 'blur'},
        ],
        empName: [
          {required: true, message: '请输入主理人名称', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {pattern:/^1\d{10}$/, message: '手机号格式有误', trigger: 'blur'},
        ],
        shop: [
          {required: true, message: '请输入默认店铺名称', trigger: 'blur'}
        ],
        city: [
          {required: true, message: '请输入店铺所在城市', trigger: 'blur'}
        ],
        smsCode: [
          {required: true, message: '请输入短信验证码', trigger: 'blur'}
        ],

      }
    }
  },
  watch:{
    'form.name':function (val){
      this.form.shop=val;
    }
  },
  methods: {
    //注册
    checkRegister: function () {
      this.$refs['form'].validate((valid) => {
        console.log(valid)
        if (valid){
          this.registerTitle();
        }
      })

    },
    //注册提示
    registerTitle:function (){
      let value="公司名称："+this.form.name+"\xa0\xa0\xa0\xa0默认店铺："+this.form.shop
      this.$confirm(value, '公司名称:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.register();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消注册'
        });
      });
    },
    register:function (){
      this.$axios({
        method: "POST",
        url: "/login/tenantRegister",
        params: this.form
      }).then(response => {
        let data = response.data
        if (data.code === 200) {
          this.$notify({
            title: '标题名称',
            message: this.$createElement('i', { style: 'color: #67C23A'}, '注册成功!默认密码:123456')
          });
          this.$router.push("/login")
        } else {
          this.$message.error('注册失败,请刷新重试');
        }
      });
    },
    //获取验证码
    getSms: function () {
      let self = this;
      this.$axios({
        method: "GET",
        url: "/login/tenantCropRegisterCode",
        params: {
          phone: self.form.phone
        }
      }).then(response => {
        console.log(response)
        let data = response.data
        if (data.code === 200) {
          this.$message.success('短信发送成功,请注意查收!');
          this.getSmsState = true;
          setTimeout(function () {
            self.getSmsState = false;
          }, 30000)
        } else {
          this.$message.error('短信发送失败,请刷新重试');
        }
      });
    },
    //跳转登陆
    toLogin: function () {
      this.$router.push('/login')
    },
    toIndex:function (){
      this.$router.push('/')
    },
  }
}
</script>

<style scoped>
/*修改浏览器滚动条开始*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;

}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f5f6f7;

}

.parent {
  height: 100%
}

.top {
  width: 100%;
  background: #FFFFFF;
  height: 80px;
}

.main {
  width: 1210px;
  margin: 0 auto;
}

.logo {
  width: 80px;
  height: 80px;
  float: left;
  /*border-radius: 50%;*/
}

.title {
  float: left;
  height: 80px;
  cursor:pointer;
}

.title p {
  padding-top: 25%;
  font-size: 22px;
}

.content {
  width: 700px;
  height: 700px;
  background: #FFFFFF;
  margin: 0 auto; /*水平居中*/
  position: relative;
  top: 45%; /*偏移*/
  transform: translateY(-50%);
  box-shadow: 0 4px 10px 0 rgba(60, 65, 71, 0.1);
}

.content-form {
  margin: 0 auto; /*水平居中*/
  position: relative;
  top: 47%; /*偏移*/
  transform: translateY(-50%);
}

.content form {
  text-align: center;
}

.content h1 {
  font-size: 34px;
}

.content input {
  width: 400px;
  height: 50px;
  display: block;
  margin: 0 auto
}

.content button {
  width: 400px;
  height: 50px;
  display: block;
  margin: 0 auto
}

.content p {
  display: inline-block;
}

.content a {
  color: #6592ec;
  font-weight: bold;
  display: inline-block;
  cursor: pointer ;
}
</style>